import React from 'react';
import Layout from '../../components/common/Layout';
import Intro from '../../components/landing/Intro';
import Features from '../../components/landing/Features';
import Testimonials from '../../components/landing/Testimonials';
import CallToAction from '../../components/landing/CallToAction';
import News from '../../components/landing/News';
import Faq from '../../components/landing/Faq';
import SEO from '../../components/SEO';
import GlobalNotice from '../../components/landing/GlobalNotice';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Nextcloud Hosting | Made in Germany | Spezialisiert seit 2012"
      description="Deine eigene Nextcloud-Installation, hosted in Deutschland. Dein digitales Zuhause für deine Daten, Fotos, Kontakte, Termine uvm."
    />
    <GlobalNotice lang="de" />
    <Intro lang="de" />
    <Features lang="de" />
    <Testimonials lang="de" />
    <CallToAction lang="de" />
    <News lang="de" />
    <Faq lang="de" />
  </Layout>
);

export default IndexPage;
